import React, { useState, useRef, useEffect } from "react";
import { deviceDetect } from "react-device-detect";
import { useMedia } from "react-media";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";

import { Box, Flex, FormLabel, PseudoBox } from "Atoms";
import { useLanguage } from "Providers";
import { CampaignUserSearchDTO, UserCount } from "../types/types";
import { GLOBAL_BREAK_POINTS } from "Constants";
import { useEffectOnce } from "Hooks";

export const CampaignCustomersCard: React.FC<{ campaignSearch?: CampaignUserSearchDTO }> = ({ campaignSearch }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const deviceInformation = deviceDetect("");
    const isASmallerScreen = useMedia({ query: GLOBAL_BREAK_POINTS.LG });
    const lastOrderRefs = Array.from({ length: campaignSearch?.shopIds.length || 1 }, () => useRef(null));
    const containerRef = useRef(null);

    const isSafariBrowser = deviceInformation.browserName === "Safari";
    const potentialCustomers = campaignSearch?.potentialUserCount || [];

    const { translate } = useLanguage();

    /** Scroll orders by offset and index */
    const scrollCampaignShops = (scrollLeft: boolean) => {
        const _currentIndex = scrollLeft ? currentIndex + 1 : currentIndex - 1;

        if (!!lastOrderRefs && containerRef.current) {
            /** Get Ref (Last Order Tile) */
            const refLastOrder = lastOrderRefs[_currentIndex]?.current
                ? (lastOrderRefs[_currentIndex].current as unknown as HTMLElement)
                : null;

            if (refLastOrder) {
                const container = containerRef.current as HTMLElement;

                /** Get Offset of next refLastOrder Tile */
                const offsetScroll = refLastOrder?.offsetLeft ?? 0;
                const finalIndex = potentialCustomers.length - 1;

                /** If you use Safari (on mac) - scroll doesn't work with 'smooth' */
                container?.scrollTo({
                    left: offsetScroll - 50,
                    behavior: isSafariBrowser ? "auto" : "smooth"
                });

                /** Behaviour if to show buttons */
                const showLeftButton = offsetScroll > 16;
                const showRightButton = finalIndex !== _currentIndex;

                setShowLeftButton(showLeftButton);
                setShowRightButton(showRightButton);
                setCurrentIndex(_currentIndex);
            }
        }
    };

    /** Setting right scoll button based on screen size + lastOrders */
    const setRightScrollButton = () => {
        /** Initial set - if last orders on a larger screen are only 2 */
        const hideRightScrollButton_LargeScreen = potentialCustomers?.length < 3 && !isASmallerScreen;
        /** Initial set - if last orders on smaller screen are only 1 */
        const hideRightScrollButton_SmallScreen = potentialCustomers?.length === 1 && isASmallerScreen;

        if (hideRightScrollButton_LargeScreen) {
            setShowRightButton(false);
        } else if (hideRightScrollButton_SmallScreen) {
            setShowRightButton(false);
        } else {
            setShowRightButton(true);
        }
    };

    /** If screen size is changed then reset scrolling  */
    const resetScroll = () => {
        if (containerRef?.current) {
            const container = containerRef.current as HTMLElement;
            /** If you use Safari (on mac) - scroll doesn't work with 'smooth' */
            container?.scrollTo({
                left: 0,
                behavior: isSafariBrowser ? "auto" : "smooth"
            });
            setShowLeftButton(false);
            setRightScrollButton();
            setCurrentIndex(0);
        }
    };

    /** Initial Set */
    useEffectOnce(() => {
        if (!!potentialCustomers?.length) {
            setRightScrollButton();
        }
    });

    useEffect(() => {
        resetScroll();
    }, [isASmallerScreen, potentialCustomers]);

    return (
        <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxW={["300px", "300px", "650px"]}
        >
            {showLeftButton && (
                <PseudoBox
                    _hover={{ cursor: "pointer" }}
                    position="absolute"
                    left={5}
                    top="25%"
                    transform="translate(-50%, -50%)"
                    paddingX={3}
                    zIndex={1}
                    mt={8}
                    height="40px"
                    bg="whiteAlpha.600"
                    rounded="xxl"
                    onClick={() => scrollCampaignShops(false)}
                >
                    <Box color="orange.600" fontSize="1.5rem" as={FaChevronLeft} height="100%" />
                </PseudoBox>
            )}
            <Flex
                position="relative"
                overflow="hidden"
                flexWrap="nowrap"
                cursor="pointer"
                pr={4}
                pb={4}
                ref={containerRef}
                width="100%"
                ml={isASmallerScreen ? 4 : 0}
                style={{ WebkitOverflowScrolling: "touch" }}
            >
                <>
                    {potentialCustomers?.map((target: UserCount, index: number) => {
                        /** If not is view make it transparent */
                        const tileIsTransparent = currentIndex !== index && isASmallerScreen;

                        const onlyOneShop = potentialCustomers.length === 1;

                        return (
                            <Flex
                                ref={lastOrderRefs[index]}
                                position="relative"
                                key={target.shopId}
                                height="130px"
                                fontWeight={400}
                                mr={4}
                                flexBasis={["25%", "25%", "60%"]}
                                whiteSpace="pre-wrap"
                                backgroundColor="base"
                                cursor={"pointer"}
                                opacity={tileIsTransparent ? "0.5" : "1"}
                                overflow="hidden"
                                transition="all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)"
                                border="1px solid #E2E2E2"
                                borderRadius="xxl"
                                minHeight="130px"
                                minWidth="150px"
                                {...(onlyOneShop && { marginLeft: "auto", marginRight: "auto" })}
                            >
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flex="1"
                                    p={2}
                                    mr={2}
                                    boxShadow="md"
                                >
                                    <FormLabel p={0} m={0} fontWeight="bold">
                                        {translate("campaign")}
                                    </FormLabel>
                                    <FormLabel p={0} m={0} fontSize="2rem" fontWeight="bold" color="teal.500">
                                        {target.numberOfPotentialUsers}
                                    </FormLabel>

                                    <FormLabel p={0} m={0} textAlign="center" fontWeight="bold">
                                        {target.shopName}
                                    </FormLabel>
                                </Flex>
                            </Flex>
                        );
                    })}
                </>
            </Flex>

            {showRightButton && (
                <PseudoBox
                    _hover={{ cursor: "pointer" }}
                    position="absolute"
                    right={1}
                    top="25%"
                    transform="translate(-50%, -50%)"
                    paddingX={3}
                    zIndex={1}
                    height="40px"
                    mt={8}
                    mr={isASmallerScreen ? 4 : 0}
                    bg="whiteAlpha.600"
                    rounded="xxl"
                    onClick={() => {
                        scrollCampaignShops(true);
                    }}
                >
                    <Box color="orange.600" fontSize="1.5rem" as={FaChevronRight} height="100%" />
                </PseudoBox>
            )}
        </Flex>
    );
};
