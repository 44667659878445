import React from "react";

import { Box, Emoji, Flex, FlexProps, Header, Image, NewDivider, Text } from "Atoms";
import { useQopla } from "Providers";
import { useLanguage } from "LanguageProvider";
import { EmailPreview } from "../types/types";
import { GET_COMPANY_SHOPS_IMAGE_URLS } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";

const EmailTopOrBottom = ({ ...props }: FlexProps) => (
    <Flex width="100%" height="40px" backgroundColor="black" alignItems="center" justifyContent="center" {...props} />
);

const GeneralEmailBackgroundImage = "https://qopla.s3.eu-west-1.amazonaws.com/email/campaign_bg.png";

export const CampaignEmailPreview: React.FC<{ emailPreview?: EmailPreview | null }> = ({ emailPreview }) => {
    const { selectedCompany } = useQopla();
    const { translate, translateWithArgument } = useLanguage();

    /** Will only get this if selected company logo url is missing */
    const { data } = useMothershipQuery<{ getCompanyShops: { imageUrl?: string }[] }>(GET_COMPANY_SHOPS_IMAGE_URLS, {
        variables: {
            companyId: selectedCompany.id
        },
        fetchPolicy: "network-only",
        skip: !!selectedCompany.imageUrl
    });

    const firstImage = data?.getCompanyShops?.filter(val => !!val.imageUrl);
    const imageUrl = selectedCompany?.imageUrl || (!!firstImage?.length ? firstImage[0].imageUrl : "");

    return (
        <Box
            border="1px solid white"
            width="300px"
            height={"380px"}
            borderRadius="xl"
            boxShadow="lg"
            backgroundColor="white"
            position="relative"
        >
            <Image
                position={"absolute"}
                top={-10}
                left={-20}
                zIndex={1}
                src={GeneralEmailBackgroundImage}
                width="340px"
                height={"400px"}
            />
            <Box
                position="absolute"
                transform="rotate(-30deg)"
                backgroundColor="blackAlpha.500"
                color="white"
                fontWeight="bold"
                borderRadius="xl"
                boxShadow="lg"
                left="-30px"
                zIndex={11}
                p={2}
                border="5px solid white"
            >
                {translate("preview")}
            </Box>
            <Flex
                bg={"white"}
                flexDirection="column"
                justifyContent="flex-start"
                height={"100%"}
                alignItems="center"
                zIndex={10}
                position={"relative"}
            >
                <EmailTopOrBottom borderTopRightRadius="xl" borderTopLeftRadius="xl" />
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent={"flex-start"}
                    height="fit-content"
                    fontWeight="bold"
                >
                    <Image alt="company logo" src={imageUrl} objectFit={"contain"} width="50px" height="50px" pt={2} />
                    <Header m={0} p={0} mt={2} fontSize="0.7rem">
                        {translate("restaurant")}
                    </Header>
                    <Text fontSize="lg" mt={2}>
                        <Emoji symbol="🔥" fontSize={"3xl"} /> {emailPreview?.name ?? translate("campaign")}
                    </Text>
                    <Header color="red.500" m={0} p={0} fontSize="lg">
                        {`${emailPreview?.discountRate ?? 0}% ${translate("discount")}`}
                    </Header>
                    <Text fontSize="0.7rem" mt={2}>
                        {"Hej 'name'!"}
                    </Text>
                    <Text marginLeft={"auto"} marginRight={"auto"} px={2} fontSize="0.5rem">
                        {translateWithArgument("campaignText", emailPreview?.discountRate ?? 0)}
                    </Text>
                    <Flex
                        height="max-content"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom="auto"
                        direction={"column"}
                    >
                        <Header fontSize="sm" m={0} p={0}>
                            {translate("discountCode")}
                        </Header>

                        <Box mt={2} border={"1px solid"} borderColor="gray.400" p={2}>
                            <Text m={0} p={0} color="yellow.800">
                                {!!emailPreview?.discountCode ? emailPreview?.discountCode : "XXXXX"}
                            </Text>
                        </Box>
                    </Flex>

                    <Flex
                        width="max-content"
                        height={"max-content"}
                        backgroundColor="newPrimary"
                        color="white"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="md"
                        mt={2}
                        mb={2}
                    >
                        <Text m={0} p={2}>
                            {translate("orderHere")}
                        </Text>
                    </Flex>
                </Flex>
                <NewDivider color={"gray.500"} />
                <Flex
                    w="100%"
                    height="max-content"
                    borderRadius="xl"
                    fontSize="0.4rem"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mb={1}
                >
                    <Flex flexDirection="column" mt={1}>
                        <Flex>
                            <Box as="span">Telefon:</Box>
                            <Box as="span"> 0707070707</Box>
                        </Flex>
                        <Flex>
                            <Box as="span">Email:</Box>
                            <Box as="span">email@test.com</Box>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Box as="span">&copy; 2024</Box>
                    </Flex>
                </Flex>
                <EmailTopOrBottom borderBottomRightRadius="xl" borderBottomLeftRadius="xl" />
            </Flex>
        </Box>
    );
};
