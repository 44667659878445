import React from "react";
import { FaCalendarAlt } from "@react-icons/all-files/fa/FaCalendarAlt";
import { NavigateFunction } from "react-router-dom";

import { QoplaPromotion } from "../types/types";
import { Modal, ModalActions, ModalBody, ModalCloseBtn } from "Molecules";
import { modalNames } from "Constants";
import { Box, Button, Flex, Header, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";

type ModalProps = {
    modalContent: {
        promotion?: QoplaPromotion;
        gotToCampaignTool: NavigateFunction;
    };
    closeModal: (modal: string) => void;
};

export const CampaignPromotionModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    const { gotToCampaignTool } = modalContent;
    const { translate, translateWithArgument } = useLanguage();

    const onHandleCloseModal = (redirect: boolean = false) => {
        closeModal(modalNames.CAMPAIGN_PROMOTION_MODAL);
        redirect && gotToCampaignTool("/admin/campaignAdmin");
    };

    return (
        <Modal
            open
            placement="center"
            isScrolling
            size="md"
            maxH="40vh"
            borderRadius={"2xl"}
            border={"5px solid white"}
            p={[0, 0, 6]}
            background={"black"}
        >
            <ModalCloseBtn color={"white"} onClick={() => onHandleCloseModal()} />
            <ModalBody mt={[8, 4]}>
                <Flex
                    justifyContent={"center"}
                    backgroundColor={"#1E1C32"}
                    h="auto"
                    borderRadius={"xl"}
                    mt={4}
                    py={4}
                    direction={"column"}
                    alignItems={"center"}
                >
                    <Header as="h2" fontSize={["xl", "xxl"]} color={"white"} pl={2} pr={2}>
                        {translate("blackWeekOffer")}
                    </Header>
                    <Text mr="auto" ml="auto" color={"white"} fontSize={["md", "lg"]} fontWeight={"600"} pl={2} pr={2}>
                        {translate("tryCampaignTool")}
                    </Text>
                    <Flex
                        mr="auto"
                        ml="auto"
                        backgroundColor={"yellow.300"}
                        p={4}
                        alignItems={"center"}
                        borderRadius={"lg"}
                        width={"max-content"}
                    >
                        <Box as={FaCalendarAlt} color="yellow.900" mr={4} />
                        <Box color={"yellow.900"} fontWeight={"600"}>
                            {translateWithArgument("offerValidUntil", "31/12")}
                        </Box>
                    </Flex>
                </Flex>
            </ModalBody>
            <ModalActions>
                <Button
                    backgroundColor={"#FFF500"}
                    color={"black"}
                    fullWidth
                    p={8}
                    borderRadius={"lg"}
                    onClick={() => onHandleCloseModal(true)}
                >
                    {translate("createCampaign")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
