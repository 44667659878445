import React from "react";
import { useFormContext } from "react-hook-form";

import { useLanguage } from "LanguageProvider";
import {
    Campaign,
    CAMPAIGN_COST_CAP_TYPE,
    COST_PER_EMAIL,
    MAX_COST_CAP,
    QoplaPromotion,
    UserCount
} from "../types/types";
import { RHFormLabelAndValue, Button, Flex, Box, FormLabel, BaseBoxProps } from "Atoms";
import { useStepWizard } from "Organisms";
import { useQoplaStore } from "Stores";
import { formatFinancialNumbers } from "PriceUtils";
import { SelectOption } from "Molecules";
import { getTotalUserCountAndCost } from "../utils/campaignFunctions";
import { formatNumber } from "NumberUtils";
import { getSimpleDateFormat } from "Utils";

/** Use real statistics in future  */
const EXAMPLE_CUSTOMER_PERCENTAGE = 0.1;
const EXAMPLE_AVERAGE_ORDER_VALUE = 200;

export const CampaignSummaryStep: React.FC<{ allShops: SelectOption[]; promotion?: QoplaPromotion }> = ({
    allShops,
    promotion
}) => {
    const { translate, translateWithArgument } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const { setStepAndAnimation, currentStep } = useStepWizard();
    const {
        control,
        getValues,
        setValue,
        formState: { isSubmitting }
    } = useFormContext<Campaign>();

    const values = getValues();

    const isCampaignPromotion = values.isQoplaPromotion;

    /** User count search will be here - can't go to this step without it */
    const potentialUserCount = values.campaignUserSearch?.potentialUserCount!;

    /** Get all shop names from search */
    const shopNames = potentialUserCount?.map((userCount: UserCount) => userCount.shopName).join(", ");

    /** Get total potential cost for all shops see function */
    const potentialFullCost = getTotalUserCountAndCost(potentialUserCount, values.maxNumberOfUsersPerShop);
    setValue("totalCost", potentialFullCost.totalCost);
    setValue(
        "costCapType",
        potentialFullCost.isCostPerShop ? CAMPAIGN_COST_CAP_TYPE.PER_SHOP : CAMPAIGN_COST_CAP_TYPE.PER_COMPANY
    );
    setValue("costCapAmount", potentialFullCost.maxCostCap);

    /** Example user count for example */
    const userCountExample = Math.ceil(potentialFullCost?.numberOfPotentialUsers * EXAMPLE_CUSTOMER_PERCENTAGE);
    const totalEarningExample = formatFinancialNumbers(userCountExample * EXAMPLE_AVERAGE_ORDER_VALUE, companyLocale);
    /** If all shops are selected this will be here to just display "All restuarant"  */
    const allShopsAreSelected = allShops?.length === values.shopIds.length;

    /** Pre format costs - actual cost is only used if the campaign was capped - to show what the cost would have been */
    const formatCalculatedCost = formatFinancialNumbers(potentialFullCost.totalCost, companyLocale);

    const formattedCostToUse = !isCampaignPromotion ? formatCalculatedCost : formatFinancialNumbers(0, companyLocale);

    const summaryWrapperProps: BaseBoxProps = { justifyContent: "space-between" };

    const hasPromotionEndDate =
        !!promotion?.endDate && isCampaignPromotion
            ? getSimpleDateFormat(new Intl.Locale(companyLocale.baseName), new Date(promotion?.endDate!))
            : "";

    return (
        <>
            <RHFormLabelAndValue name="name" languageKey="name" control={control} wrapperProps={summaryWrapperProps} />
            {/* MVP 2 
            <Box display="flex" alignItems="center" flexDirection="row" mb={3}>
                <FormLabel>{`${translate("theme")}: `}</FormLabel>
                <FormLabel fontWeight="normal">
                    {!!values.theme ? translate(values.theme as Languagekey) : "-"}
                </FormLabel>
            </Box> */}
            <RHFormLabelAndValue
                name="campaignCode"
                languageKey="discountCode"
                control={control}
                wrapperProps={summaryWrapperProps}
            />
            <RHFormLabelAndValue
                name="discountRate"
                languageKey="discount"
                control={control}
                extraText={"%"}
                wrapperProps={summaryWrapperProps}
            />
            <RHFormLabelAndValue
                name="startDate"
                languageKey="startDate"
                control={control}
                isDate
                wrapperProps={summaryWrapperProps}
            />
            <RHFormLabelAndValue
                name="endDate"
                languageKey="endDate"
                control={control}
                isDate
                wrapperProps={summaryWrapperProps}
            />
            <Box display="flex" alignItems="center" {...summaryWrapperProps} flexDirection="row" mb={3}>
                <FormLabel>{`${translate("restaurants")}: `}</FormLabel>
                <FormLabel fontWeight="normal">
                    {allShopsAreSelected ? translate("allRestaurants") : shopNames}
                </FormLabel>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="row" {...summaryWrapperProps} mb={5}>
                <FormLabel>{`${translate("potentialCustomersAndEmail")}: `}</FormLabel>
                <Box as="span" display={"flex"} alignItems="center" flexDirection="row">
                    <FormLabel fontWeight="normal">
                        {formatNumber(potentialFullCost.numberOfPotentialUsers, companyLocale)}
                    </FormLabel>
                    <FormLabel fontWeight={"normal"}>{`(${formatFinancialNumbers(
                        potentialFullCost.costPerUser,
                        companyLocale
                    )} / ${translate("customer")})`}</FormLabel>
                </Box>
            </Box>
            {isCampaignPromotion && (
                <Box display="flex" alignItems="center" flexDirection="row" {...summaryWrapperProps} mb={3}>
                    <FormLabel>{`${translate("ordinaryPrice")}: `}</FormLabel>
                    <FormLabel fontWeight="normal" style={{ textDecoration: "line-through" }}>
                        {`${formatCalculatedCost} ${translate("excludingVAT")}`}
                    </FormLabel>
                </Box>
            )}
            <Box display="flex" alignItems="center" flexDirection="row" {...summaryWrapperProps} mb={3}>
                <FormLabel>{`${translate("yourCost")}: `}</FormLabel>
                <FormLabel fontWeight="normal" color={isCampaignPromotion ? "red.500" : ""}>
                    {`${formattedCostToUse} ${!isCampaignPromotion ? translate("excludingVAT") : ""}`}
                    <Box as="span" fontWeight={"bold"} color="yellow.800">
                        *
                    </Box>{" "}
                </FormLabel>
            </Box>
            <Box
                border="solid 1px black"
                borderRadius="5px"
                p="4"
                flexDirection="row"
                mb={5}
                backgroundColor="gray.100"
            >
                {translate("example")}: {`${translate("if")} `}
                <Box as="span" fontWeight="600">
                    {userCountExample} {translate("customers").toLowerCase()}
                </Box>{" "}
                {`${translate("campaignExampleText")} `}
                <Box as="span" fontWeight="600">
                    {totalEarningExample}
                </Box>
                .
            </Box>
            {!isCampaignPromotion && (
                <Box
                    display="flex"
                    alignItems="center"
                    color={"yellow.800"}
                    fontWeight={"bold"}
                    flexDirection="row"
                    mb={5}
                >
                    {`* ${translate("payingForPromo")}`}
                </Box>
            )}
            {isCampaignPromotion && (
                <Box
                    color={"red.500"}
                    fontWeight={"600"}
                >{`* ${translateWithArgument("promoOfferText", hasPromotionEndDate!)}`}</Box>
            )}
            <Flex alignSelf="flex-end" justifyContent="space-between" mt={4}>
                <Button
                    variant="outline"
                    size="lg"
                    onClick={() => currentStep && setStepAndAnimation(currentStep, "BACK")}
                >
                    {translate("back")}
                </Button>
                <Button type="submit" themeColor="teal" size="lg" isLoading={isSubmitting}>
                    {translate("save")}
                </Button>
            </Flex>
        </>
    );
};
