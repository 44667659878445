import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment, { Moment } from "moment";

import { ALL_SHOP_VALUE, Campaign, SetEmailPreviewFunction } from "../types/types";
import { Button, Flex, RHDatePickerInput, RHFormInput, RHSelectInput, SelectOption, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { CountryLocale } from "Types";
import { usePartialStepValidation } from "../hooks/usePartialStepValidation";
import { getMinimumEndDateFromStartDate } from "Utils";
import { CampaignWizardButtons } from "./CampaignWizardButtons";
import { useStepWizard } from "Organisms";

type Props = {
    countryLocale: CountryLocale;
    allShops: SelectOption[];
    setEmailPreview: SetEmailPreviewFunction;
    onCancelCampaign: () => Promise<void>;
};

export const CampaignDetailsStep: React.FC<Props> = ({
    countryLocale,
    allShops,
    setEmailPreview,
    onCancelCampaign
}) => {
    const { translate } = useLanguage();

    const { isNextStep } = useStepWizard();
    const { control, setValue, watch } = useFormContext<Campaign>();

    /** This step needs to be validated before moving on */
    const { isValid } = usePartialStepValidation<Campaign>(["name", "shopIds"], control, true);

    /** Locale code for date picker */
    const datePickerLocaleCode = countryLocale.localeCode.toLowerCase();

    const name = watch("name");
    const shopIds = watch("shopIds");
    const startDate = watch("startDate");
    const endDate = watch("endDate");

    /** MVP 2 */
    /*  const themeOptions = Object.keys(CAMPAIGN_THEME).map((value: string) => ({
        value,
        label: translate(value as Languagekey)
    }));
 */
    const minimumEndDate = getMinimumEndDateFromStartDate(startDate?.toString());

    /** If min end date changes then check & possible change of end date */
    useEffect(() => {
        if (!!endDate && !!startDate) {
            if (moment(endDate).isBefore(startDate)) {
                setValue("endDate", minimumEndDate.toDate());
            }

            // Thanks to the default dates had to add this to make sure it didn't go further than a month
            if (moment(endDate).diff(moment(startDate), "days") > 31) {
                setValue("endDate", moment(startDate).add(1, "month").toDate());
            }
        }
    }, [minimumEndDate]);

    useEffect(() => {
        setEmailPreview("name", name);
    }, [name]);

    useEffect(() => {
        if (shopIds?.length > 1 && shopIds?.includes(ALL_SHOP_VALUE)) {
            /** If someone add "All Restaurants" remove all other ids */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }
        if (shopIds?.length === allShops.length && allShops.length > 3) {
            /** If all shops are added then swap for "All Resturants" */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }

        if (!isNextStep) {
            /** If clicked back to this step remove user search as this can cause issues when trying to search again
             * as the user search is not valid anymore
             */
            setValue("campaignUserSearch", undefined, { shouldDirty: true, shouldValidate: true });
        }
    }, [shopIds]);

    useEffect(() => {
        if (allShops?.length > 3 && isNextStep) {
            /** Add "All Restaurants" when entering the campaign tool (if number of shops is greater than 3) */
            setValue("shopIds", [ALL_SHOP_VALUE], { shouldDirty: true, shouldValidate: true });
        }
        if (allShops?.length === 1) {
            setValue("shopIds", [allShops[0].value], { shouldDirty: true, shouldValidate: true });
        }
    }, [allShops?.length >= 1]);

    const allShopSelect: SelectOption = { label: translate("allRestaurants"), value: ALL_SHOP_VALUE };
    const hasAlotOfShops = allShops.length > 3;
    const shopOptions = hasAlotOfShops ? [allShopSelect, ...allShops] : allShops;

    const updatedOptions = shopIds?.includes(ALL_SHOP_VALUE)
        ? shopOptions.map(option => ({ ...option, isDisabled: option.value !== ALL_SHOP_VALUE }))
        : shopOptions;

    /** Hide if only one shop */
    const hideShopSelection = allShops?.length === 1;

    return (
        <>
            <RHFormInput
                control={control}
                name="name"
                formLabel={translate("campaignName")}
                isMandatory
                tooltipLabelText={translate("campaignExample")}
                showExtendedLabelText
            />
            {!hideShopSelection ? (
                <RHSelectInput
                    control={control}
                    name="shopIds"
                    formLabel={translate("restaurants")}
                    options={updatedOptions}
                    isMandatory
                    isMulti
                    isClearable={allShops.length > 1}
                />
            ) : null}

            <Flex width="100%">
                <RHDatePickerInput
                    control={control}
                    name="startDate"
                    formLabel={translate("startDate")}
                    dateFormat="YYYY-MM-DD"
                    locale={datePickerLocaleCode}
                    minDate={moment().add(1, "day")}
                    maxDate={moment().add(15, "day")}
                    autoComplete={"off"}
                    transformBeforeOnChange={(moment: Moment) => {
                        return moment.toDate();
                    }}
                    isMandatory
                    isFullWidth
                    wrapperProps={{ width: "100%", marginRight: 2 }}
                />
                <RHDatePickerInput
                    control={control}
                    name="endDate"
                    formLabel={translate("endDate")}
                    dateFormat="YYYY-MM-DD"
                    locale={datePickerLocaleCode}
                    minDate={minimumEndDate.add(1, "day")}
                    maxDate={moment(startDate).add(1, "month")}
                    autoComplete={"off"}
                    transformBeforeOnChange={(moment: Moment) => {
                        return moment.toDate();
                    }}
                    isMandatory
                    isFullWidth
                    wrapperProps={{ width: "100%", marginLeft: 2 }}
                />
            </Flex>
            {/** NOT MVP 1 */}
            {/*             <RHSelectInput
                control={control}
                name="theme"
                formLabel={translate("theme")}
                options={themeOptions}
                isMandatory
            /> */}
            <CampaignWizardButtons isValid={isValid}>
                <Button variant="outline" themeColor="red" size="lg" onClick={onCancelCampaign}>
                    {translate("cancel")}
                </Button>
            </CampaignWizardButtons>
        </>
    );
};
