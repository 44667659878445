import React, { PropsWithChildren } from "react";

import { Button, Flex } from "Atoms";
import { useStepWizard } from "Organisms";
import { useLanguage } from "Providers";

type WizardButtons = {
    isValid: boolean;
};

export const CampaignWizardButtons: React.FC<PropsWithChildren<WizardButtons>> = ({ isValid = false, children }) => {
    const { setStepAndAnimation, currentStep } = useStepWizard();
    const { translate } = useLanguage();

    return (
        <Flex alignSelf="flex-end" justifyContent="space-between">
            {!!children ? (
                <>{children}</>
            ) : (
                <Button
                    variant="outline"
                    size="lg"
                    onClick={() => currentStep && setStepAndAnimation(currentStep, "BACK")}
                >
                    {translate("back")}
                </Button>
            )}

            <Button
                backgroundColor="newPrimary"
                color="white"
                size="lg"
                _hover={{ backgroundColor: "primaryAlpha.800" }}
                isDisabled={!isValid}
                onClick={() => currentStep && setStepAndAnimation(currentStep, "FORWARD")}
            >
                {translate("next")}
            </Button>
        </Flex>
    );
};
