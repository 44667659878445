import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
    Campaign,
    CAMPAIGN_DISCOUNT_TYPE,
    discountRateSelectOptions,
    maxRedeemedSelectOptions,
    SetEmailPreviewFunction
} from "../types/types";
import { RHFormInput, RHCheckBoxInput, RHSelectInput, Text } from "Atoms";
import { useLanguage } from "Providers";
import { usePartialStepValidation } from "../hooks/usePartialStepValidation";
import { CampaignWizardButtons } from "./CampaignWizardButtons";

export const CampaignDiscountStep: React.FC<{ setEmailPreview: SetEmailPreviewFunction }> = ({ setEmailPreview }) => {
    const { translate } = useLanguage();
    const { control, trigger, watch, setValue, getValues } = useFormContext<Campaign>();

    /** Watch discount type - for valdiation don't need a code for individual as this will be generated in the backend */
    const discountType = watch("discountType");
    const campaignCode = watch("campaignCode");
    const discountRate = watch("discountRate");
    const emailOnlyDiscount = watch("emailOnlyDiscount");

    /** Partial validation ready for next step */
    const { isValid } = usePartialStepValidation<Campaign>(["campaignCode", "discountRate"], control, true);

    /** Trigger validation on campiagn code / Group needs a code
     * Individual is for MVP 2
     */
    useEffect(() => {
        if (discountType === CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL) {
            setValue("campaignCode", undefined, { shouldValidate: true, shouldDirty: false });
        } else {
            trigger("campaignCode");
        }
    }, [discountType]);

    useEffect(() => {
        setEmailPreview("discountCode", campaignCode ?? "");
        setEmailPreview("discountRate", discountRate ?? 0);
    }, [campaignCode, discountRate]);

    useEffect(() => {
        if (!emailOnlyDiscount) {
            setValue("maxRedeemed", 0);
        }
    }, [emailOnlyDiscount]);

    // MVP 2
    //const isGroupDiscount = discountType === CAMPAIGN_DISCOUNT_TYPE.GROUP;

    return (
        <>
            {/** MVP 2 */}
            {/*     
                <RHRadioButtonGroup
                activeThemeColor="purple"
                name="discountType"
                formLabel={translate("discountType")}
                control={control}
                rounded={20}
                value={CAMPAIGN_DISCOUNT_TYPE.GROUP}
                wrapperProps={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    mb: isGroupDiscount ? "0" : 10
                }}
            >
                <CustomRadioButton value={CAMPAIGN_DISCOUNT_TYPE.GROUP}>
                    {translate(CAMPAIGN_DISCOUNT_TYPE.GROUP)}
                </CustomRadioButton>
                <CustomRadioButton value={CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL}>
                    {translate(CAMPAIGN_DISCOUNT_TYPE.INDIVIDUAL)}
                </CustomRadioButton>
            </RHRadioButtonGroup> */}
            {/*             {isGroupDiscount ? (
                <FormLabel display="flex" justifyContent="flex-end" fontSize="0.7rem" mb={4}>
                    {"* Group: One code for everyone"}
                </FormLabel>
            ) : null}
            */}
            <RHFormInput
                name="campaignCode"
                formLabel={`${translate("discountCode")}`}
                control={control}
                maxLength={20}
                isFullWidth
                isUpperCase
                tooltipLabelText={translate("discountCodeExample")}
                showExtendedLabelText
            />
            <RHCheckBoxInput
                name="emailOnlyDiscount"
                control={control}
                formLabel={`* ${translate("limitTheDiscountToCustomer")}`}
            />
            <Text m={-3} p={0} color={"yellow.800"} fontSize={"sm"} ml={2}>
                {`* ${translate("emailOnlyExplanation")}`}
            </Text>
            {emailOnlyDiscount && (
                <RHSelectInput
                    name="maxRedeemed"
                    control={control}
                    formLabel={`${translate("maxUsagePerUser")} ${translate("zeroIsUnlimited")}`}
                    options={maxRedeemedSelectOptions}
                    isDisabled={!emailOnlyDiscount}
                    wrapperProps={{ marginTop: 8 }}
                />
            )}

            <RHSelectInput
                name="discountRate"
                formLabel={`${translate("discount")} (%)`}
                control={control}
                options={discountRateSelectOptions}
                wrapperProps={{ marginTop: !emailOnlyDiscount ? 8 : 0 }}
                isMandatory
            />

            <CampaignWizardButtons isValid={isValid} />
        </>
    );
};
