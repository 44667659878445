import { useEffect } from "react";
import { Control, FieldValues, useFormState } from "react-hook-form";

import { useStepWizard, ValidStep } from "Organisms";

type PartialValidationReturn = { isValid: boolean };

type PropValidation<T> = (keyof T)[];

/**
 * [HOOK] - RHF Partial validation of a form - will keep it in here for now (could be useful in other step wizard forms)
 * @param {(keyof T)[]} propNamesToValidate - Prop names from form type
 * @param control - form control RHF
 * @returns
 */
export const usePartialStepValidation = <T extends FieldValues>(
    propNamesToValidate: PropValidation<T>,
    control: Control<T, any>,
    validateStep: boolean = false
): PartialValidationReturn => {
    /** Step 1: Get errors & dirty fields from form state */
    const { errors, dirtyFields } = useFormState({ control });

    /** Step 2: Get steps and validate function from Wizard */
    const { currentStep, stepsToValidate, setStepsToValidate } = useStepWizard();

    /** Step 3: Check that all fields in validation have been filed in */
    const allPropsAreDirty = propNamesToValidate.every((value: keyof T) => dirtyFields.hasOwnProperty(value));

    /** Step 4: Make sure that all props sent in don't have any errors */
    const hasNoErrors = propNamesToValidate.every((value: keyof T) => !errors?.[value]);

    useEffect(() => {
        /** Step 5: Set whole Step to valid if requested */
        if (validateStep) {
            const steps = [...(stepsToValidate || [])];
            const containsCurrentStep = steps.some(value => value.step === currentStep);
            if (!containsCurrentStep) {
                const updateSteps = [...steps, { step: currentStep!, valid: allPropsAreDirty && hasNoErrors }];
                setStepsToValidate(updateSteps);
            } else {
                const stepsValid = steps.map((value: ValidStep) => {
                    if (value.step === currentStep) {
                        return {
                            step: value.step,
                            valid: allPropsAreDirty && hasNoErrors
                        };
                    }
                    return value;
                });
                setStepsToValidate(stepsValid);
            }
        }
    }, [allPropsAreDirty && hasNoErrors]);

    /** If all values are dirty & no erros - gives a partial validation */
    return {
        isValid: allPropsAreDirty && hasNoErrors
    };
};
